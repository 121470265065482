<div class="point__setup">
  <div class="point__setup--title">
    <div class="form-group__title">
      <h4 class="mr-2" style="margin-bottom: 0;">{{title}}</h4>
      <span *ngIf="isTooltip" data-toggle="tooltip" data-placement="top" class="tooltip__parent"
        title="{{tooltipContent}}">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path
            d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM7 1.4C7.77 1.4 8.33 2.03 8.26 2.8L7.7 8.4H6.3L5.74 2.8C5.67 2.03 6.23 1.4 7 1.4ZM7 12.6C6.23 12.6 5.6 11.97 5.6 11.2C5.6 10.43 6.23 9.8 7 9.8C7.77 9.8 8.4 10.43 8.4 11.2C8.4 11.97 7.77 12.6 7 12.6Z"
            fill="#828282" />
        </svg>
      </span>
    </div>
    <span class="update__date"><em>Ngày cập nhật:</em>
      {{updateTime ? (updateTime | date: 'dd/MM/yyyy') : '-/-'}}
    </span>
    <span class="get-data-test" (click)="getDataTest()">Data Test</span>
  </div>
  <div class="small__line"></div>
  <div class="point__setup--content">
    <p *ngIf="hintText">{{hintText}}</p>
    <div *ngFor="let item of list; index as index">
      <div class="big__line" *ngIf="index === list?.length - 1"></div>
      <form-row-start-end [index]="index" [point]="item" [isFirst]="index === 0" [titleStart]="config?.titleStart"
        [titleEnd]="config?.titleEnd" [titleValue]="config?.titleValue" [isLasted]="index === list?.length - 1"
        (result)="receiveDataForm($event, index)" [isInputYear]="true">
      </form-row-start-end>

      <div class="big__line" *ngIf="index >= 0 && index < list?.length - 1"></div>

      <div class="icon-add add__form mb-4" *ngIf="index === list?.length - 2">
        <img style="cursor: pointer;" (click)="addForm()" src="./assets/images/icons/add.svg" class="mr-2" />
        <span style="cursor: pointer;" (click)="addForm()">Thêm</span>
      </div>

    </div>

    <div class="w-100 d-flex" *ngIf="list?.length">
      <button class="w-100 btn common__btn" (click)="emitData()" [disabled]="checkDisabled()">
        Lưu
      </button>
    </div>
  </div>
</div>