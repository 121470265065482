import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";
import { Subscription } from "rxjs";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { Constant } from "src/app/common/constant";
import { DialogService } from "src/app/common/service/dialog.service";
import { AppQueries } from "../../../../../state";
import { FormEditCheckChangeComponent } from "../../../../../common/extend-code/form.edit.check.change.component";
import { InputValueStartEndComponent } from "../../../share-plan/components/input-value-start-end/input-value-start-end.component";
import { ArrayUtil } from "../../../../../common/utils/array.util";

const TABS = {
  ACTIVE: "ACTIVE",
  PASSIVE: "PASSIVE",
};

@Component({
  selector: "tab-config-income-annual-growth",
  styleUrls: ["./tab-config-income-annual-growth.component.scss"],
  templateUrl: "./tab-config-income-annual-growth.component.html",
})
export class TabConfigIncomesAnnualGrowthComponent
  extends FormEditCheckChangeComponent
  implements OnInit
{
  @ViewChild(InputValueStartEndComponent)
  inputValueStartEndComponent: InputValueStartEndComponent;
  public Constant = Constant;
  subscriptions?: Subscription[] = [];
  @Input() configActive: any;
  @Input() configPassive: any;
  TABS = TABS;
  tab: string;
  nextTab: string;
  config: any;
  constructor(
    public eventManager: EventManagerService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public cdf: ChangeDetectorRef,
    private appQueries: AppQueries,
    public sessionStorageService: SessionStorageService
  ) {
    super(eventManager);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isShowWarningChange = false;
    this.parentId = "config-annual";
    this.emitComponent();
    this.changeTab(TABS.ACTIVE);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configActive) {
      setTimeout(() => {
        if (!this.configActive) {
          this.configActive = {};
        }
      }, 200);
    }

    if (changes.configPassive) {
      setTimeout(() => {
        if (!this.configPassive) {
          this.configPassive = {};
        }
      }, 200);
    }
  }
  changeTab(tab) {
    this.tab = tab;
    if (tab === TABS.ACTIVE) {
      this.config = {
        type: "ACTIVE",
        title: "Thu nhập chủ động",
        hintText: `Thiết lập tỷ lệ tăng trưởng dự kiến của thu nhập hàng năm để tính dòng tiền thu nhập tương lai cho khách hàng. Đây là thiết lập mức chung, bạn có thể tùy chỉnh riêng cho từng hồ sơ HĐTC.`,
        updateTime: this.configActive.updateTime,
        list: ArrayUtil.clone(this.configActive.list),
        typeId: this.configActive.typeId,
        isTooltip: true,
        tooltipContent:
          "Thu nhập chủ động bao gồm thu nhập từ Lương và nguồn thu nhập chủ động khác.",
      };
    } else {
      this.config = {
        type: "PASSIVE",
        title: "Thu nhập thụ động",
        hintText: `Thiết lập tỷ lệ tăng trưởng dự kiến của thu nhập hàng năm để tính dòng tiền thu nhập tương lai cho khách hàng. Đây là thiết lập mức chung, bạn có thể tùy chỉnh riêng cho từng hồ sơ HĐTC.`,
        updateTime: this.configPassive.updateTime,
        list: ArrayUtil.clone(this.configPassive.list),
        typeId: this.configPassive.typeId,
        isTooltip: true,
        tooltipContent:
          "Thu nhập thụ động bao gồm thu nhập từ Lãi đầu tư, Tiền bản quyền và nguồn thu nhập thụ động khác.",
      };
    }
  }
  checkChangeIncome(tab) {
    this.inputValueStartEndComponent.confirmChangeRouter().then((res) => {
      if (res) {
        this.changeTab(tab);
      } else {
        this.nextTab = tab;
        this.showWarning();
      }
    });
  }
  checkTab(tab) {
    this.inputValueStartEndComponent.confirmChangeRouter().then((res) => {
      if (res) {
        this.changeTab(tab);
      } else {
        this.confirmChangeRouter();
      }
    });
  }
  checkChangeRouter() {
    return !this.inputValueStartEndComponent.isChanged;
  }

  showWarning(message?, title?) {
    super.showWarning(message, title);
    this.eventManager.broadcast({
      name: "show-backdrop-dialog",
    });
  }
  clearData(): void {}
  funcWarning() {
    this.clearData();
    this.changeTab(this.nextTab);
  }
}
