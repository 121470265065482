<div [hidden]="readonly">
  <textarea [style.background]="background" [style.min-height]="minHeight" [ngClass]="isError ? 'border-red' : ''"
    name="" #autoResizeTextarea placeholder="{{placeholder}}" rows="{{rows}}" cols="{{cols}}" (input)="onInput()"
    disabled="{{isDisabled}}" [(ngModel)]="value" (ngModelChange)="changeValue()" (keydown)="catchKeydown($event)"
    class="form-control input-note"></textarea>
  <!-- <div class="text-right w-100" *ngIf="maxLength">
    <span>Ký tự: {{value?.length || 0}}/{{maxLength}}</span>
  </div> -->
</div>
<div *ngIf="readonly">
  <div [ngClass]="isNoAll ? '' : 'text-content'" [innerHTML]="viewValue"> </div>
</div>