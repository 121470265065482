import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { Chart } from "chart.js";
import { Constant } from "../../constant";
import { debounceTime, Subject, Subscription } from "rxjs";

declare let $;

@Component({
  selector: "app-chart-js-pie-chart",
  templateUrl: "./chart-js-pie-chart.component.html",
  styleUrls: ["./chart-js-pie-chart.component.scss"],
})
export class ChartJsPieChartComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() height: number = 250;
  @Input() noLabels: boolean = false;
  @Input() noBorderGrid: boolean = false;
  @Input() cutout: string = "100%";
  isReload: boolean = false;
  isDevice: boolean = false;
  isAfterViewInit: boolean = false;
  id: string =
    Math.round(Math.random() * 1000) + "-" + Math.round(Math.random() * 1000);
  public chart: any;
  createChart$ = new Subject();
  subscription: Subscription;
  @Input() width: any;
  @Input() dataChart;
  @Input() chartData: any = {
    labels: [""],
    datasets: [
      {
        label: "",
        data: [300, 50, 100],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };
  @Output() onComplete: EventEmitter<any> = new EventEmitter();
  constructor() {}
  ngOnInit() {
    this.width = this.width || window.innerWidth;
    this.isDevice =
      Constant.checkAndroid() || Constant.checkIOS() ? true : false;
    this.subscription = this.createChart$
      .pipe(debounceTime(1000))
      .subscribe((event) => {
        return this.createChart();
      });
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    const changes: any = simpleChanges;
    if (changes.dataChart && changes.dataChart.currentValue) {
      if (changes.dataChart.firstChange) {
        setTimeout(() => {
          this.createChart$.next(true);
        }, 200);
      } else {
        this.createChart$.next(true);
      }
    }
  }
  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
    this.subscription.unsubscribe();
  }
  ngAfterViewInit(): void {}
  createChart() {
    if (!this.dataChart) {
      return;
    }
    if (this.chart) {
      this.chart.destroy();
    }
    this.chartData = JSON.parse(JSON.stringify(this.dataChart));
    this.chartData.labels = this.noLabels
      ? this.chartData.labels.map((item: any) => {
          return "";
        })
      : this.chartData.labels;
    if (!$("#" + "pieChart" + this.id).length) {
      return;
    }
    this.chart = new Chart("pieChart" + this.id, {
      type: "pie",
      data: this.chartData,
      options: {
        cutout: this.cutout,
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: this.isDevice ? 0.85 : 2.5,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || "";

                if (label) {
                  label += ": ";
                }

                if (context.raw !== undefined) {
                  let value = context.raw.toString().replace(",", ".");
                  label += ` ${value}` + "%";
                }

                return label;
              },
            },
          },
        },
        animation: {
          onComplete: (chart) => {
            if (chart.initial) {
              this.onComplete.emit(true);
            }
          },
        },
      },
    });
    // this.chart.resize(this.width, this.height);
  }
}
