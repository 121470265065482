import {  OnInit, OnDestroy, Injector, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { EventManagerService } from '../service/event-manager.service';
import { EditComponent } from './edit.component';
import { Constant } from '../constant';

@Injectable({ providedIn: 'any' })
export abstract class FormEditCheckChangeComponent extends EditComponent implements OnInit, OnDestroy {
  isFirstLoadForm: boolean = false;
  isChanged: boolean = false;
  isHasExistValue: boolean = false;
  isStartCheckChange: boolean = false;
  isShowWarningChange: boolean = true;
  checkChange$ = new Subject();
  resolvePromise: any;
  parentId: string;
  constructor(
    public eventManager: EventManagerService,
  ) {
    super(eventManager)
  }
  ngOnInit() {
    super.ngOnInit();
    this.subsCommon.push(
      this.checkChange$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.checkChange();}),
      this.eventManager.subscribe('on-change-input', (res) => {
        // console.log('subscribe on-change-input: ', this);
        this.changeInput(false);
      }),
      this.eventManager.subscribe('get-current-tab', (res) => {
        this.emitComponent();
      }),
      this.eventManager.subscribe('reset-is-change', (res) => {
        this.resetDefaultChange();
      }),
    )
    if (this.editForm) {
      this.subsCommon.push(
        this.editForm.valueChanges.subscribe((val) => {
          if (!this.isStartCheckChange) {
            return;
          }
          // console.log('valueChanges: ', val);
          // console.log('component: ', this);
          this.checkChange$.next(true);
          this.checkErrorForm();
        }),
      )
    }
  }
  checkUpdateForm() {
    if (this.isFirstLoadForm) {
      return;
    }
    this.isFirstLoadForm = true;
    setTimeout(() => {
      this.updateForm();  
    }, 200);
  }
  parseData(): void {
    // console.log('super parse Data has value changes: ', this);
    setTimeout(() => {
      this.startCheckChange();
      this.subscriptions.push(
        this.editForm.valueChanges.subscribe((val) => {
          this.changeInput();
        })
      )
    }, 500);
  }
  emitComponent() {
    this.eventManager.broadcast({
      name: 'emit-current-tab',
      content: {
        component: this,
        parentId: this.parentId
      }
    })
  }
  changeInput(isBroadCast = true) {
    if (!this.isStartCheckChange) {
      return;
    }
    // console.log('isChanged = true : ', this);
    this.isChanged = true;
    if (!isBroadCast) {
      return;
    }
    // console.log('this change input : ', this);
    this.eventManager.broadcast({
      name: 'on-change-input'
    });
  }
  changeInputCalculate() {
    this.changeInput();
    this.startCalculateChange();
  }
  startCalculateChange() {
    this.calculateChange$.next(true);
  }
  startCheckChange() {
    // console.log('start check change: ', this);
    this.isStartCheckChange = true;
    this.checkChange$.next(true);
  }
  checkIsChanged() {
    return this.isChanged;
  }
  checkChange() {
    this.isChanged = this.checkIsChanged();
    // console.log('checkChange isChanged: ', this.isChanged);
  }
  checkDisabled() {
    return this.isStartCheckChange && this.isHasExistValue && !this.isChanged;
  }
  checkChangeRouter() {
    return !this.isChanged;
  }
  resetChange() {
    this.resetDefaultChange();
    this.eventManager.broadcast({
      name: 'reset-is-change',
      content: {}
    })
  }
  resetDefaultChange() {
    // this.isStartCheckChange = false;
    // this.isHasExistValue = false;
    console.log('reset change');
    this.isChanged = false;
  }
  confirmChangeRouter(isNoShowWarningChange?) {
    return new Promise<boolean>((resolve, reject) => {
      if (this.checkChangeRouter()) {
        resolve(true);
        return;
      } else {
        if (!isNoShowWarningChange && this.isShowWarningChange) {
          this.resolvePromise = resolve;
          this.showWarning();
        } else {
            resolve(false);
        }
      }
    });
  }
  clearData() {

  }
  showWarning(message?, title?) {
    message = message || Constant.text_check_change;
    title = title || 'Chưa lưu';
    const properties = {
      message,
      title,
      type: 'confirm',
      isNoDismissAll: true,
      cancelFunc: this.cancelFuncWarning.bind(this),
      func: this.funcWarning.bind(this),
    };
    this.eventManager.broadcast({
      name: 'show-dialog',
      content: properties
    })
  }
  cancelFuncWarning() {
    this.resolvePromise && this.resolvePromise(false);
  }
  funcWarning() {
    this.clearData();
    this.resolvePromise && this.resolvePromise(true);
  }
}
