import { AfterViewInit, Component, OnDestroy, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Constant } from "src/app/common/constant";
import { UserProfile } from "src/app/common/models/users.model";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { AppQueries } from "src/app/state";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NormalPoint } from "../normal.point.model";
import { debounceTime, Subject } from "rxjs";
import { FormEditCheckChangeComponent } from "../../../../../../common/extend-code/form.edit.check.change.component";
/**
 * declare Jquery   
 * */
declare let $: any;

@Component({
  selector: "form-row-start-end",
  templateUrl: "./form-row-start-end.component.html",
  styleUrls: ["./form-row-start-end.component.scss"],
})
export class FormRowStartEndComponent extends FormEditCheckChangeComponent implements OnInit, OnDestroy {
  relationalOperators: any[] = [];
  startOperators: any[] = [];
  endOperators: any[] = [];

  public editForm = new FormGroup({
    startPoint: new FormControl(null, Validators.required),
    endPoint: new FormControl(null, Validators.required),
    startOperator: new FormControl('', Validators.required),
    endOperator: new FormControl('', Validators.required),
    value: new FormControl(null, Validators.required),
  });
  @Input() titleStart;
  @Input() titleEnd;
  @Input() titleValue;
  @Input() point: NormalPoint;
  @Input() index;
  @Input() isLasted;
  @Input() isFirst;
  @Input() isInputYear: boolean = false;
  @Output() result: EventEmitter<any> = new EventEmitter();
  userProfile: UserProfile;
  public Constant = Constant;
  emitDataChange$ = new Subject();
  constructor(
    private fb: FormBuilder,
    private cdf: ChangeDetectorRef,
    public eventManager: EventManagerService,
    public route: ActivatedRoute,
    public appQueries: AppQueries,
  ) {
    super(eventManager)
  }
  ngOnInit() {
    super.ngOnInit();
    this.subscriptions = [
      this.appQueries.userProfile$
      .subscribe((userProfile) => {
        this.userProfile = userProfile || new UserProfile();
      }),
      this.emitDataChange$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.emitData();}),
      this.appQueries.sourceInfos$.subscribe((sourceInfos) => {
        if (!sourceInfos) {
          return;
        }
        this.relationalOperators = sourceInfos.relationalOperators || [];
        this.startOperators = this.relationalOperators.filter(item => item.code === '>' || item.code === '>=');
        this.endOperators = this.relationalOperators.filter(item => item.code === '<' || item.code === '<=');
        this.subscribeState.next();
      }),
      this.eventManager.subscribe('emit-data-form-row-start-end', (res) => {
        this.isSaved = true;
        this.checkChange$.next(true);
      }),  
      this.eventManager.subscribe('change-endOperator-form-row-start-end', (res) => {
        if (!this.isStartCheckChange || !res.content.value) {
          return;
        }
        if (this.index !== res.content.index + 1) {
          return;
        } 
        const reverseOperator = this.changeReverseOperator(res.content.value);
        this.patchValue('startOperator', reverseOperator);
      }),  
      this.eventManager.subscribe('change-endPoint-form-row-start-end', (res) => {
        if (!this.isStartCheckChange || this.index !== res.content.index + 1) {
          return;
        } 
        this.patchValue('startPoint', res.content.value);
      }), 
      this.editForm.get('startOperator').valueChanges.subscribe(
        value => {
          if (!this.isStartCheckChange || !this.isLasted || !value) {
            return;
          }
          const reverseOperator = this.changeReverseOperator(value);
          this.patchValue('endOperator', reverseOperator);
        }
      ),
      this.editForm.get('endOperator').valueChanges.subscribe(
        value => {
          if (value === '<=') {
            this.patchValue('endOperator', '<');
            setTimeout(() => {
              this.changeEndOperator();  
            }, 500);
            return;
          }
          if (!this.isStartCheckChange || !this.isLasted || value) {
            return;
          }
          const reverseOperator = this.changeReverseOperator(this.editForm.value.startOperator);
          this.patchValue('endOperator', reverseOperator);
        }
      ),
      this.editForm.get('startPoint').valueChanges.subscribe(
        value => {
          if (!this.isStartCheckChange) {
            return;
          } 
          this.calculateChange$.next(true);
        }
      ),
      this.editForm.get('endPoint').valueChanges.subscribe(
        value => {
          if (!this.isStartCheckChange) {
            return;
          } 
          this.calculateChange$.next(true);
          this.eventManager.broadcast({
            name: 'change-endPoint-form-row-start-end',
            content: {
              index: this.index,
              value
            }
          });
        }
      )
    ];
    if (this.isLasted) {
      this.editForm.controls['endPoint'].setValidators(null);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.point) {
      this.detailObject = new NormalPoint(this.point);
      this.checkUpdateForm();
    }
  }
  updateForm() {
    this.subscribeState.next();
  }
  parseData(): void {
    if (!this.point) {
      return;
    }
    const startOperator = this.point.startOperator || ((this.startOperators || []).find(item => item.id === this.point.startOperatorId) || {}).code;
    const endOperator = this.point.endOperator || ((this.endOperators || []).find(item => item.id === this.point.endOperatorId) || {}).code;
    this.detailObject.startOperator = startOperator;
    this.detailObject.endOperator = endOperator;
    this.editForm.patchValue(this.detailObject);
    super.parseData();
    this.calculateChange$.next(true);
    this.isHasExistValue = this.point.startPoint || this.point.endPoint ? true : false;
  }
  calculateTotal(): void {
    if (this.editForm.value.startPoint < new Date().getFullYear()) {
      this.patchValue('startPoint', new Date().getFullYear());
    }
    const endPoint = this.editForm.value.endPoint;
    if (endPoint && endPoint <= this.editForm.value.startPoint) {
      this.patchValue('endPoint', this.editForm.value.startPoint + 1);
    }
  }
  changeStartOperator() {
    setTimeout(() => {
      const reverseOperator = this.changeReverseOperator(this.editForm.value.startOperator);
      this.patchValue('endOperator', reverseOperator);
    }, 200)
    
  }
  changeEndOperator() {
    setTimeout(() => {
      this.eventManager.broadcast({
        name: 'change-endOperator-form-row-start-end',
        content: {
          index: this.index,
          value: this.editForm.value.endOperator
        }
      });
    }, 200)
  }
  disabledButton() {
    const startPoint = this.editForm.value.startPoint;
    const endPoint = this.editForm.value.endPoint;
    if (endPoint && startPoint >= endPoint) {
      this.errors.startPoint = true;
    } else this.errors.startPoint = false;

    if (endPoint && endPoint < 1) {
      this.errors.endPoint = true;
    } else this.errors.endPoint = false;

    return super.disabledButton();
  }

  emitData() {
    if (!this.isSaved) {
      const body = this.editForm.value;
      this.result.emit(body);
      return;  
    }
    const result = this.disabledButton();
    if (result || this.editForm.invalid) {
      this.result.emit({isError: true});
      return;
    } else {
     this.point.isError = false;
    }
    const startOperatorId = ((this.startOperators || []).find(item => item.code === this.editForm.value.startOperator) || {}).id;
    const endOperatorId = ((this.endOperators || []).find(item => item.code === this.editForm.value.endOperator) || {}).id;
    this.result.emit({
      startPoint: this.editForm.value.startPoint,
      endPoint: this.editForm.value.endPoint,
      value: this.editForm.value.value,
      startOperatorId,
      endOperatorId,
    });
    return;
  }
  deletePoint() {
    this.eventManager.broadcast({
      name: 'delete-form-row-start-end',
      content: {
        index: this.index
      }
    });
  }
  changeReverseOperator(operator) {
    let reverseOperator = '';;
    switch (operator) {
      case '>':
        reverseOperator = '<=';
      break;
      case '<':
        reverseOperator = '>=';
      break;
      case '>=':
        reverseOperator = '<';
      break;
      case '<=':
        reverseOperator = '>';
      break;
    } 
    return reverseOperator;
  }
  patchValueNumber(key, value) {
    super.patchValue(key, value ? parseFloat(value) : null);
    this.checkChange$.next(true);
  }
  checkChange() {
    super.checkChange();
    this.emitData();
  }
}
