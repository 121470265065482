<div class="pie-chart-comprehensive-plan" [ngClass]="isVertical ? 'vertical-chart' : 'horizontal-chart'"
  *ngIf="!isLoading; else loadingChart" [attr.id]="id">
  <div class="chart-data d-flex align-items-center " *ngIf="dataChart && isDrawChart; else noDataChart"
    [ngClass]="isVertical ? 'flex-column' : ''">
    <app-chart-js-pie-chart [dataChart]="dataChart" [width]="150" [height]="150" [cutout]="cutout">
    </app-chart-js-pie-chart>

    <div class="d-flex flex-column legend-container">
      <!-- <div class="d-flex chart-legend align-items-center mb-1" *ngFor="let name of dataChart?.labels; index as index">
        <div class="chart-legend-color mr-3" [style.backgroundColor]="dataChart.datasets[0]?.backgroundColor[index]">
        </div>
        <span class="chart-legend-name mr-2">{{name}}</span>
        <span><strong>{{dataChart.datasets[0]?.data[index]}}% </strong></span>
        <span class="ml-auto">
          <strong>
            <span *ngIf="isQuickView">~ {{dataChart.datasets[0]?.label[index] |money}}</span>
          </strong>
        </span>
      </div> -->
      <table class="table notice__table" *ngIf="dataChart.datasets?.length">
        <tbody>
          <tr *ngFor="let name of dataChart?.labels; index as index">
            <td class="chart-legend-name mr-2">
              <div class="chart-legend-status">
                <div class="chart-legend-color mr-3"
                  [style.backgroundColor]="dataChart.datasets[0]?.backgroundColor[index]"></div>
                <span> {{name}}</span>
              </div>
            </td>
            <td><strong>{{dataChart.datasets[0]?.data[index]}}% </strong></td>
            <td class="ml-auto">
              <strong>
                <span *ngIf="isQuickView && dataChart.datasets[0]?.amount">~ {{dataChart.datasets[0]?.amount[index] |
                  money}}đ</span>
              </strong>
            </td>
          </tr>
        </tbody>
      </table>


    </div>
  </div>
  <ng-template #noDataChart>
    <div class="no-data">
      <img src="./assets/images/icons/no-data-pie-chart.svg" width="100" height="100">
      <span class="mt-3 data-message text-center" [ngClass]="isNoMessage ? 'd-none' : ''">{{message}}</span>
    </div>
  </ng-template>
</div>

<ng-template #loadingChart>
  <div class="d-flex justify-content-center  align-items-center">
    <img src="./assets/images/icons/loading-background.svg" width="100" height="100">
  </div>
</ng-template>