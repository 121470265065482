import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";
import { Subscription } from "rxjs";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { Constant } from "src/app/common/constant";
import { DialogService } from "src/app/common/service/dialog.service";
import { AppQueries } from "../../../../../state";
import { FormEditCheckChangeComponent } from "../../../../../common/extend-code/form.edit.check.change.component";
import { InputValueStartEndComponent } from "../../../share-plan/components/input-value-start-end/input-value-start-end.component";

@Component({
  selector: "tab-config-inflationary-annual-growth",
  styleUrls: ["./tab-config-inflationary-annual-growth.component.scss"],
  templateUrl: "./tab-config-inflationary-annual-growth.component.html",
})
export class TabConfigInflationarysAnnualGrowthComponent
  extends FormEditCheckChangeComponent
  implements OnInit, OnChanges
{
  @ViewChild(InputValueStartEndComponent)
  inputValueStartEndComponent: InputValueStartEndComponent;
  public Constant = Constant;
  subscriptions?: Subscription[] = [];
  @Input() config: any;
  @Input()
  hintText: "Thiết lập tỷ lệ tăng trưởng dự kiến của lạm phát hàng năm để tính phân bổ chi tiêu trong tương lai, kế hoạch nghỉ hưu và mục tiêu tài chính khác cho khách hàng. Đây là thiết lập mức chung, bạn có thể tùy chỉnh riêng cho từng hồ sơ HĐTC.";
  list: any[] = [];
  typeId: any;
  constructor(
    public eventManager: EventManagerService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public cdf: ChangeDetectorRef,
    private appQueries: AppQueries,
    public sessionStorageService: SessionStorageService
  ) {
    super(eventManager);
  }

  ngOnInit() {
    super.ngOnInit();
    this.parentId = "config-annual";
    this.emitComponent();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      if (!this.config) {
        this.config = {};
        this.list = [];
      } else {
        this.list = this.config.list || [];
        this.typeId = this.config.typeId;
        this.config.hintText = `Thiết lập tỷ lệ tăng trưởng dự kiến của lạm phát hàng năm để tính phân bổ chi tiêu trong tương lai,
         kế hoạch nghỉ hưu và mục tiêu tài chính khác cho khách hàng. Đây là thiết lập mức chung, bạn có thể tùy chỉnh riêng cho từng hồ sơ HĐTC.`;
      }
    }
  }
  checkChangeRouter() {
    return !this.inputValueStartEndComponent.isChanged;
  }
}
