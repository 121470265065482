import {
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { Constant } from "src/app/common/constant";


@Injectable({ providedIn: 'any' })
export class TabCommonCheckChangeComponent implements OnInit, OnDestroy, OnChanges
{
  @Input() profile;
  @Input() idTab: string;
  public Constant = Constant;
  subscriptions?: Subscription[] = [];
  TABS: any;
  activeTabs: any = {};
  tab: string;
  profileId: any = '';
  completeTab: number = 0;
  selectedComponent: any;
  nextTab: string;
  constructor(
    public eventManager: EventManagerService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.eventManager.subscribe("emit-current-tab", (res) => {
        if (this.idTab === res.content.parentId) {
          this.selectedComponent = res.content.component;
        }
      }),
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.profile && changes.profile.currentValue) {
      if (this.profile) {
        this.checkStatusTab();
      }
    }
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.eventManager.destroys(this.subscriptions);
    }
  }
  checkStatusTab() {}
  chooseTab(tab) {
    this.nextTab = tab;
    this.checkTab();
  }
  checkTab() {
    if (!this.selectedComponent) {
      this.changeTab(this.nextTab);
      return;
    }
    return new Promise((resolve, reject) => {
      this.selectedComponent.confirmChangeRouter(true)
      .then((res) => {
        if (res) {
          this.changeTab(this.nextTab);
          resolve(true); 
        } else {
          this.showWarning();
          resolve(false); 
        }
      })
    })
  }
  changeTab(tab) {
    this.tab = tab;
    this.moveRouter();
    setTimeout(() => {
      this.eventManager.broadcast({
        name: 'get-current-tab'
      });
    }, 500)
  }
  moveRouter() {}
  showWarning(message?, title?) {
    message = message || Constant.text_check_change;
    title = title || 'Chưa lưu';
    const properties = {
      message,
      title,
      type: 'confirm',
      isNoDismissAll: true,
      cancelFunc: this.cancelFuncWarning.bind(this),
      func: this.funcWarning.bind(this),
    };
    this.eventManager.broadcast({
      name: 'show-dialog',
      content: properties
    })
  }
  cancelFuncWarning() {}
  funcWarning() {
    this.changeTab(this.nextTab);
  }
}
