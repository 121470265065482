import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessPopupComponent } from '../components/success-popup/success-popup';
import { ErrorPopupComponent } from '../components/error-popup/error-popup';
import { ConfirmPopupComponent } from '../components/confirm-popup/confirm-popup';
import { WarningPopupComponent } from 'src/app/common/components/warning-popup/warning-popup';
import { NoConnectInternetComponent } from "../components/no-connect-internet-popup/no-connect-internet-popup";
import { EventManagerService } from "./event-manager.service";
import { debounceTime, Subject, Subscription } from "rxjs";
/**
 * declare Jquery
 * */
declare let $: any;

@Injectable({ providedIn: 'root' })
export class DialogService {
  public modals: any = {};
  subscriptions?: Subscription[] = [];
  openPopup$ = new Subject();
  selectedModal: {model: any, class: string, static: boolean, properties: any};
  constructor(
    public eventManager: EventManagerService,
    private modalService: NgbModal
  ) {
    this.openPopup$.pipe(
      debounceTime(200),
      ).subscribe((resolve)=> { return this.showModal(resolve);})
  }
  showConfirm(properties) {
    this.selectedModal = {
      model: ConfirmPopupComponent,
      static: true,
      class: 'confirm-popup-modal small-modal',
      properties,
    }
    return new Promise<boolean>((resolve, reject) => {
      this.openPopup$.next(resolve);
    })
  }
  showError(message: any, title?: string, properties?: any) {
    properties = properties || {};
    properties.message = message;
    properties.title = title || 'Có lỗi';
    this.selectedModal = {
      model: ErrorPopupComponent,
      static: properties.backdrop,
      class: 'error-popup-modal',
      properties,
    }
    return new Promise<boolean>((resolve, reject) => {
      this.openPopup$.next(resolve);
    })
  }

  showSuccess(message?: string, title?: string, properties?: any) {
    properties = properties || {};
    properties.message = message;
    properties.title = title || 'Thành công';
    this.selectedModal = {
      model: SuccessPopupComponent,
      static: properties.backdrop,
      class: 'success-popup-modal',
      properties,
    }
    return new Promise<boolean>((resolve, reject) => {
      this.openPopup$.next(resolve);
    })
  }
  showWarning(message?: string, title?: string, properties?: any) {
    properties = properties || {};
    properties.message = message;
    properties.title = title || 'Thông báo';
    this.selectedModal = {
      model: WarningPopupComponent,
      static: properties.backdrop,
      class: 'warning-popup-modal',
      properties,
    }
    return new Promise<boolean>((resolve, reject) => {
      this.openPopup$.next(resolve);
    })
  }
  showNoConnectInternet(message?: string, title?: string, properties?: any) {
    properties = properties || {};
    return new Promise<boolean>((resolve, reject) => {
      const options: NgbModalOptions = {};
      options.centered = true;
      options.backdrop = false;
      options.windowClass  = 'no-connect-internet-popup-modal';
      const hasOpenModal = this.modalService.hasOpenModals();
      const modalRef = this.modalService.open(NoConnectInternetComponent, options);
      modalRef.componentInstance.result
      .subscribe((res) => {
        location.reload();
      });
    })
  }
  checkModalExist(modal) {
    if ($('#' + modal).hasClass('show')) {
      return true;
    } else return false;
  }
  destroy() {
    this.modalService.dismissAll();
  }
  showModal(resolve) {
    if (!this.selectedModal) {
      return;
    }
    if (!this.selectedModal.properties.isNoDismissAll) {
      this.modalService.dismissAll();
    }
    this.eventManager.broadcast({name: 'close-modal', content: {}});
    const options: NgbModalOptions = {};
    options.centered = true;
    options.windowClass = this.selectedModal.class;
    const modalRef = this.modalService.open(this.selectedModal.model, options);
    this.selectedModal.properties = this.selectedModal.properties || {};
    Object.keys(this.selectedModal.properties).forEach((key) => {
      modalRef.componentInstance[key] = this.selectedModal.properties[key];
    });
    modalRef.componentInstance.result
    .subscribe((res) => {
      resolve(res);
    });
  }
}
