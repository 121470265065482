<div class="row">
    <div class="col-md-3 mb-3">
        <ul class="nav nav-pills flex-column" id="income" role="tablist">
            <li class="nav-item">
                <a class="nav-link" id="active-tab" [ngClass]="config?.type === 'ACTIVE' ? 'active' : ''"
                    (click)="checkChangeIncome(TABS.ACTIVE)">
                    <div class="common__block--result">

                        <div class="element ">
                            <h5><img src="./assets/images/icons/ic-uncheck.svg" alt="">
                                Thu nhập chủ động</h5>
                            <span class="update__date"><em>Ngày cập nhật:</em>
                                {{configActive?.updateTime ? (configActive?.updateTime | date: 'dd/MM/yyyy') : '-/-'}}
                            </span>
                        </div>
                    </div>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="passive-tab" [ngClass]="config?.type === 'PASSIVE' ? 'active' : ''"
                    (click)="checkChangeIncome(TABS.PASSIVE)">
                    <div class="common__block--result green">

                        <div class="element ">

                            <h5><img src="./assets/images/icons/ic-uncheck.svg" alt=""> Thu nhập thụ động</h5>
                            <span class="update__date"><em>Ngày cập nhật:</em>
                                {{configPassive?.updateTime ? (configPassive?.updateTime | date: 'dd/MM/yyyy') : '-/-'}}
                            </span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <!-- /.col-md-4 -->
    <div class="col-md-9">

        <input-value-start-end [title]="config?.title" [hintText]="config?.hintText" [updateTime]="config?.updateTime"
            [list]="config?.list" [typeId]="config?.typeId" [isTooltip]="config?.isTooltip"
            [tooltipContent]="config?.tooltipContent">
        </input-value-start-end>
    </div>
    <!-- /.col-md-8 -->
</div>