import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef} from "@angular/core";
import { Constant } from "../../constant";
import { Subject, Subscription, debounceTime } from 'rxjs';
import { NumberUtil } from "../../utils/number.util";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'input-number',
  templateUrl: "./input-number.html",
  styleUrls: ['./input-number.scss'],
})

export class InputNumberComponent implements OnInit, OnChanges {
    @Input('amount') amount: any;
    @Input('unit') unit: any;
    @Input('maxLength') maxLength: any;
    @Input('placeholder') placeholder: any;
    @Input('disabled') disabled: boolean = false;
    @Input('isDecimal') isDecimal: boolean = false;
    @Input('maxLengthDecimal') maxLengthDecimal: any;
    @Input('isInteger') isInteger: boolean = true;
    @Input('isSeparate') isSeparate: boolean = true;
    @Input('isError') isError: boolean = false;
    @Output() amountChange: EventEmitter<any> = new EventEmitter();
    @Output() result: EventEmitter<any> = new EventEmitter();
    amountText: string = '';
    Constant = Constant;
    changeInputText$ = new Subject();
    beforeKeydown: any;
    minValue: any;
    maxValue: any;
    @Input('id') id: string = '' + Math.round(Math.random() * 1000) + Math.round(Math.random() * 1000);
    constructor(
        private cdf: ChangeDetectorRef
    ) {

    }
    ngOnInit() {
        this.changeInputText$.pipe(
            debounceTime(100),
            ).subscribe((event)=> { return this.parseData();});
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.amount &&  !NumberUtil.isNull(changes.amount.currentValue)) {
            const amountRemoveComma = this.amountText ? NumberUtil.removeCommaForString(this.amountText) : null;
            if (amountRemoveComma) {
                if (!amountRemoveComma.includes(changes.amount.currentValue + '')) {
                    this.changeText();
                }
            } else {
                this.changeText();
            }
        }

        if (changes.maxLength &&  changes.maxLength.currentValue) {
            this.findMaxAndMinValue();
        }
        if (changes.maxLengthDecimal &&  changes.maxLengthDecimal.currentValue) {
            this.findMaxAndMinValue();
        }
    }
    
    findMaxAndMinValue() {
        let max = '';
        for (let i=0; i< this.maxLength; i++) {
            max += '9';
        }
        if (this.isDecimal) {
            max += '.';
            for (let i=0; i< this.maxLengthDecimal; i++) {
                max += '9';
            }   
        }
        this.maxValue = parseFloat(max);
        this.minValue = this.maxValue * -1;
    }
    changeValue() {
        this.changeInputText$.next(true);
    }
    parseData() {
        this.checkValidateData();
        this.amountChange.emit(this.amount);
        setTimeout(() => {
            this.result.emit(this.amount);
        }, 10)
    }
    checkValidateData() {
        if (this.amountText && this.amountText !== '-' && isNaN(parseFloat(this.amountText))) {
            this.amount = null;
            this.amountText = null;
            return;
        }
        if (this.amountText === '') {
            this.amount = null;
            return;
        }
        let isNegative = false;
        let amountText = this.amountText;
        if (this.amountText[0] === '-') {
            isNegative = true;
            amountText = this.amountText.substring(1);
        }
        if (this.isSeparate) {
            const amount = amountText ? NumberUtil.revertMoneytoNumber(amountText) : null;
            this.amount =  !NumberUtil.isNull(amount) ? amount * (isNegative ? -1 : 1) : null;

            if (this.amount === null) {
                return;
            }
            if (this.isDecimal) {
                this.amount = NumberUtil.decimalAdjust(this.amount, this.maxLengthDecimal);
            } else {
                this.amount = Math.round(this.amount);
            }

            if (this.isInteger) {
                if (this.amount < 0) {
                    this.amount = this.amount * -1;
                    this.amountText = this.amount + '';
                }
            }
            if (this.maxValue && this.amount > this.maxValue ) {
                this.amount = this.maxValue;
                this.amountText = NumberUtil.numberToMoney(this.amount);
            }
            this.addComma();
        } else {
            this.amount = amountText || null;
            const length = (Math.abs(this.amount) + '').length;
            if (length > this.maxLength) {
                this.amount = this.amount.slice(0, this.maxLength);
                this.cdf.detectChanges();
            }
        }
        // check length number 
        this.checkMaxAndMinValue();

        if (this.amount === null) {
            return;
        }
    }
    addComma() {
        let amountText = NumberUtil.removeCommaForString(this.amountText);
        const split = amountText.split('.');
        this.amountText = NumberUtil.numberToMoney(split[0]) + (split.length > 1 ? '.' + split[1] : '');
    }
    checkMaxAndMinValue() {
        if (this.amountText.length < 2) {
            return;
        }
        if  (this.amount < this.minValue) {
            this.amount = this.minValue;
            this.amountText = this.amount + '';
        }
        
        if  (this.amount > this.maxValue) {
            this.amount = this.maxValue;
            this.amountText = this.amount + '';
        }
    }
    changeText() {
        if (!this.isSeparate) {
            this.amountText = !NumberUtil.isNull(this.amount) ? this.amount + '' : '';
            return;
        }
        let changeText = !NumberUtil.isNull(this.amount)  ? NumberUtil.numberToMoney(this.amount) : '';
        if (this.checkDifferentText(changeText)) {
            this.amountText = changeText;
        }
    }
    checkDifferentText(changeText) {
        if (changeText === '0') {
            let amountText = this.amountText.replaceAll('.', '');
            amountText = amountText.replaceAll(',', '');
            return parseInt(changeText) !== parseInt(amountText);
        }
        if ('-' === this.amountText) {
            return false;
        }
        if (changeText + '.' === this.amountText) {
           return false;
        }
        return true;
    }
    catchKeydown(event) {
        const key = event.key;
        // input decimal
        if ( this.isDecimal && key === '.') {
            const amount = (this.amountText || '').replaceAll(',', '');
            const positionDots = amount.indexOf('.');
            if (positionDots > -1) {
                event.preventDefault();
            }
            return;
        }

        // input negative
        if ( !this.isInteger && key === '-') {
            return;
        }   

        if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab' || key === 'Delete' ) {
           return;
        }
        if (key === 'Control') {
            this.beforeKeydown = key;
        }

        if ((key === 'v' && this.beforeKeydown === 'Control'  )) {
            this.beforeKeydown = '';
            return;
        }

        if ((key === 'c' && this.beforeKeydown === 'Control'  )) {
            this.beforeKeydown = '';
            return;
        }
        if ((key === 'a' && this.beforeKeydown === 'Control'  )) {
            this.beforeKeydown = '';
            return;
        }
        if ((key === 'x' && this.beforeKeydown === 'Control'  )) {
            this.beforeKeydown = '';
            return;
        }

        if (key >= '0' && key <= '9') {
          
            const selection: any = document.getSelection();
            if (selection.toString() && selection.baseNode.id === 'input-group-' + this.id ) {
                return;
            }
            let amount = (this.amountText || '').replaceAll(',', '');
            let isNegative = false;
            if (amount[0] === '-') {
                isNegative = true;
                amount = amount.substring(1);
            }
            if (!this.isSeparate && amount.length === this.maxLength) {
                event.preventDefault();
                return;
            }
            const textField : any = document.getElementById('input-' + this.id);
            const cursorPosition = textField.selectionStart;
            const positionDots = amount.indexOf('.');
            if (cursorPosition <= positionDots || positionDots === -1) {
                if (this.maxLength) {
                    const lengthNumber = ((amount + '').split('.')[0] || '').length;
                    if (lengthNumber >= this.maxLength) {
                        event.preventDefault();
                        return;
                    }
                }
            } else {
                if (this.isDecimal) {
                    const lengthDecimal = ((amount + '').split('.')[1] || '').length;
                    if (lengthDecimal >= this.maxLengthDecimal) {
                        event.preventDefault();
                        return;
                    }
                }
            }
        }

        if (key < '0' || key > '9') {
            event.preventDefault();
            return;
        }
        return;
    }
}
