import {Injectable} from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  constructor(
   
  ) {
  }
  static register(quantity, callback?) {
    let subscribe: any = {
      subscriptions: [],
      index: 0,
      quantity,
      isComplete: false,
      complete: null,
      subscribeState: new Subject<any>()
    };
    for (let i=0; i< quantity; i++) {
      subscribe.subscriptions.push(false);
    }
    subscribe.next = () => {
      if (subscribe.isComplete) {
        return;
      }
      subscribe.index++;
      subscribe.subscriptions[subscribe.index - 1] = true;
      if (subscribe.subscriptions.every(value => value)) {
        subscribe.subscribeState.next(true);
        setTimeout(() => {
          subscribe.reset();
          subscribe.isComplete = true;
          subscribe.complete && subscribe.complete.unsubscribe();
        }, 200)
      }
    }
    const subscribeComplete = subscribe.complete = () => {
      return subscribe.subscribeState.asObservable();
    };
    subscribe.complete = subscribeComplete().subscribe(() => {
      callback && callback();
    });
    subscribe.reset = () => {
      subscribe.index = 0;
      subscribe.subscriptions = [];
      for (let i=0; i< subscribe.quantity; i++) {
        subscribe.subscriptions[i] = false;
      }
    }
    subscribe.update = (quantity) => {
      subscribe.quantity = quantity; 
      subscribe.reset();
    }
    return subscribe;
  }
}
