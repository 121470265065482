import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Constant } from "src/app/common/constant";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { FinancialNormalPlanService } from "../normal-plan.service";
import { AppQueries } from "src/app/state";
import { DialogService } from "src/app/common/service/dialog.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PopupHasCheckChangeComponent } from "../../../../common/extend-code/popup-has-check-change.component";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: "config-weight-assessment-item-popup",
  templateUrl: "./config-weight-assessment-item-popup.component.html",
  styleUrls: ["./config-weight-assessment-item-popup.component.scss"],
})
export class ConfigWeightAssessmentItemPopupComponent
  extends PopupHasCheckChangeComponent
  implements OnInit, OnDestroy
{
  public Constant = Constant;
  configItems: any = [];
  @Input() config: any = {};
  isEdit: boolean = false;
  @Input() isConfigPoint: boolean = false;
  @Input() isHasNormalProfile: boolean = false;
  total: any;
  textRules: any;
  userProfile: any;
  constructor(
    private fb: FormBuilder,
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
    public financialPlanService: FinancialNormalPlanService,
    private appQueries: AppQueries,
    public dialogService: DialogService,
    private sanitizer: DomSanitizer,
    public ngModal: NgbModal
  ) {
    super(eventManager, activeModal, dialogService);
  }
  ngOnInit(): void {
    this.eventBoardCast = this.dataForm.eventBoardCast;
    this.updateForm();
    this.subscriptions = [
      this.appQueries.userProfile$.subscribe((userProfile) => {
        this.userProfile = userProfile;
      }),
      this.appQueries.sourceInfos$.subscribe((sourceInfos) => {
        if (!sourceInfos) {
          return;
        }
        let textRules = sourceInfos.healthCheckReferenceRules || '{}';
        textRules = JSON.parse(textRules) || {};
        this.textRules = this.sanitizer.bypassSecurityTrustHtml(textRules.CONFIG_WEIGHT);
        const configItems =
          sourceInfos.financialHealthCheck.healthCheckEvaluations || [];
        this.configItems = JSON.parse(JSON.stringify(configItems));
        this.configItems = this.configItems.filter(item => item.code !== 'SAVE_INVEST');
        this.updateForm();
      }),
    ];
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    const backdrop = document.getElementsByTagName('ngb-modal-backdrop')[0];
    if (backdrop) {
        backdrop.classList.remove("large-z-index-backdrop");
    }
  }
  getConfigPoint() {
    if (!this.userProfile.isAccessPlan) {
      return;
    }
    this.financialPlanService.getConfigPoint().subscribe((res: any) => {
      this.config = res.data || {};
      const keys = Object.keys(this.config);
      this.isConfigPoint = keys.length && keys.every((key) => this.config[key]);
      this.updateForm();
    });
  }
  dismiss() {
    this.activeModal.dismiss();
  }
  updateForm(): void {
    if (!this.configItems.length) {
      return;
    }
    this.configItems.forEach((item) => {
      item.value = this.config[item.code];
    });
    const isNoValue = this.configItems.every(item => !item.value);
    if (isNoValue) {
      this.configItems.forEach((item) => item.value = item.recommendPoint);
    }
    this.total = this.configItems.reduce(
      (partialSum, a) => partialSum + (a.value || 0),
      0
    );
  }
  changeValue(item, value) {
    item.value = value;
    this.disabledButton();
  }
  disabledButton(): boolean {
    if (this.configItems.some((item) => !item.value)) {
      this.errors.isNull = true;
    } else this.errors.isNull = false;

    this.total = this.configItems.reduce(
      (partialSum, a) => partialSum + (a.value || 0),
      0
    );
    if (this.total !== 100) {
      this.errors.percent = true;
    } else this.errors.percent = false;

    this.configItems.some((item) => !item.value);
    return super.disabledButton();
  }
  save() {
    this.isSaved = true;
    const result = this.disabledButton();
    if (result) {
      return;
    }
    const body = {
      evaluationConfigs: this.configItems.map((item) => {
        return {
          evaluationCode: item.code,
          point: item.value,
        };
      }),
    };
    return this.financialPlanService.updateConfigPoint(body).subscribe(
      (res: any) => {
        this.isEdit = false;
        this.dialogService.showSuccess(
          "Lưu thiết lập thành công!",
          "Thành công", {isNoDismissAll: true}
        );

        this.eventManager.broadcast({
          name: 'show-backdrop-dialog',
        });
   
        this.eventManager.broadcast({
          name: "save-config",
          content: {},
        });
      
      },
      (err) => {
        // TODO
      }
    );
  }
  getDataTest() {
    const object = {
      INCOME_COST: Math.floor(Math.random() * 15 + 5),
      DEBT: Math.floor(Math.random() * 15 + 5),
      OTHER_FINANCIAL_GOAL: Math.floor(Math.random() * 15 + 5),
      INSURANCE: Math.floor(Math.random() * 15 + 5),
      RETIREMENT: 0,
    };
    object.RETIREMENT =
      100 -
      object.INCOME_COST -
      object.DEBT -
      object.OTHER_FINANCIAL_GOAL -
      object.INSURANCE;
    this.configItems.forEach((item) => {
      item.value = object[item.code];
    });
    this.disabledButton();
  }
  confirmChange() {
    this.isSaved = true;
    const result = this.disabledButton();
    if (result) {
      return;
    }
    if (!this.isConfigPoint || !this.isHasNormalProfile) {
      this.callApi$.next(true);
    } else {
      this.dialogService.showWarning(
        "Khi chỉnh sửa trọng số thiết lập, kết quả đánh giá của tất cả hồ sơ đã tạo sẽ bị thay đổi, bạn có muốn thực hiện?",
        "Thay đổi thiết lập trọng số",
        { isNoDismissAll: true, titleOk: "Đồng ý", func: this.save.bind(this)}
      );
      this.eventManager.broadcast({
        name: 'show-backdrop-dialog',
      });
    }
  }
  checkDismiss() {
    super.checkDismiss();
  }
  checkIsChanged() {
    return this.isEdit && this.configItems.some((item) => {
      return item.value !== this.config[item.code];
    });
  }
}
