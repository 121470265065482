import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { EventManagerService } from '../service/event-manager.service';
import { Constant } from '../constant';
import { DateUtil } from '../utils/date.util';
import { SubscriptionService } from '../service/subscription.service';

@Injectable({ providedIn: 'any' })
export abstract class EditComponent implements OnInit, OnDestroy {
  @Input() queryParams: any = {};
  id: any;
  editForm: any;
  detailObject: any = {};
  errors: any = {};
  isSaved: boolean = false;
  isCallingAPI: boolean = false;
  isUpload: boolean = false;
  subscriptions?: Subscription[] = [];
  subsCommon?: Subscription[] = [];
  callApi$ = new Subject();
  calculateChange$ = new Subject();
  checkErrorForm$ = new Subject();
  errorMessage: string = '';
  valueCalculate: any = {};
  public Constant = Constant;
  subscribeState: any;
  constructor(
    public eventManager: EventManagerService,
  ) {
  }
  ngOnInit() {
    this.subscribeState = SubscriptionService.register(2, this.parseData.bind(this));
    this.subsCommon = [
      this.eventManager.subscribe('upload-file', (res) => {
        this.isUpload = true;
      }),
      this.eventManager.subscribe('upload-fail', (res) => {
        this.isUpload = true;
      }),
      this.callApi$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.callAPI(event);}),
      this.calculateChange$.pipe(
        debounceTime(1000),
      ).subscribe((event)=> { return this.calculateTotal();}),
      this.checkErrorForm$.pipe(
        debounceTime(500),
      ).subscribe((event)=> { return this.disabledButton();}),
    ];
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.eventManager.destroys(this.subscriptions);
    }
    this.eventManager.destroys(this.subsCommon);
  }
  onSuccess(res) {}
  onError(error) {}
  waitUpdateForm() {
    setTimeout(() => {
      this.updateForm()
    }, 200)
  }
  updateForm() {}
  calculateTotal() {}
  parseData() {}
  checkErrorForm() {
    this.checkErrorForm$.next(true);
  }
  disabledButton() {
    const keys = Object.keys(this.errors);
    const key = keys.find(key => this.errors[key]);
    return key ? true : false;
  }
  callAPI(event?) {
    this.save();
  }
  executeCallAPI(event?) {
    this.callApi$.next(event || true);
  }
  patchValue(key, value) {
    this.editForm.patchValue({
      [key]: value
    })
  }
  patchDate(key, value) {
    const date = DateUtil.parseDate( DateUtil.revertDate(value, 'dd/MM/yyyy'), 'yyyyMMdd');
    if (value && date !== this.editForm.value[key]) {
      this.editForm.patchValue({
        [key]: date
      })
    }
  }
  preventClickDuplicate() {
    console.log('isCallingAPI: ', new Date());
    // this.eventManager.broadcast({
    //   name: 'show-spinner',
    //   content: {}
    // });
    if (this.isCallingAPI) {
      console.log('prevent', new Date());
      return true;
    }
    this.isCallingAPI = true;
    setTimeout(() => {
      this.isCallingAPI = false;
      // this.eventManager.broadcast({
      //   name: 'close-spinner',
      //   content: {}
      // });
    }, 3000)
    return false;
  }
  save() {}
}
