import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { DialogService } from "src/app/common/service/dialog.service";
import { FormBuilder, Validators } from "@angular/forms";
import { AppQueries } from "src/app/state";
import { NormalPoint } from "./normal.point.model";
import { ObjectUtil } from "../../../../../common/utils/object.util";
import { ConfigPoint } from "./config.point.model";
import { ComprehensivePlanService } from "../../../comprehensive-plan/comprehensive-plan.service";
import { FormEditCheckChangeComponent } from "../../../../../common/extend-code/form.edit.check.change.component";

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: "input-value-start-end",
  styleUrls: ["./input-value-start-end.component.scss"],
  templateUrl: "./input-value-start-end.component.html",
})
export class InputValueStartEndComponent
  extends FormEditCheckChangeComponent
  implements OnInit, OnChanges
{
  @Input() title;
  @Input() updateTime;
  @Input() typeId;
  @Input() assetId;
  @Input() config: ConfigPoint;
  @Input() data;
  @Input() hintText: string =
    "Thiết lập tỷ lệ tăng trưởng dự kiến của sản phẩm đầu tư hàng năm để tính dòng tiền tài sản tương lai cho khách hàng. Đây là thiết lập mức chung, bạn có thể tùy chỉnh riêng cho từng hồ sơ HĐTC.";
  @Input() list: any[] = [];
  @Input() isTooltip: boolean = false;
  @Input() tooltipContent;
  constructor(
    public formBuilder: FormBuilder,
    public eventManager: EventManagerService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public sessionStorageService: SessionStorageService,
    private comprehensivePlanService: ComprehensivePlanService,
    private appQueries: AppQueries
  ) {
    super(eventManager);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions = [
      this.appQueries.sourceInfos$.subscribe((sourceInfos) => {
        if (!sourceInfos) {
          return;
        }
        let references = sourceInfos.healthCheckEvaluationReferences || "[]";
        references = references === "{}" ? "[]" : references;
      }),
      this.eventManager.subscribe("delete-form-row-start-end", (res) => {
        this.deleteForm(res.content.index);
        this.changeInput(false);
      }),

      this.eventManager.subscribe("on-change-input", (res) => {
        this.changeInput(false);
      }),
    ];
    this.setDefaultConfig();
  }

  ngAfterViewInit() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list) {
      setTimeout(() => {
        this.checkEmptyList();
      }, 200);
    }
    if (changes.config) {
      setTimeout(() => {
        this.setDefaultConfig();
      }, 200);
    }
  }
  checkEmptyList() {
    this.isChanged = false;
    this.startCheckChange();
    if (this.list && this.list.length) {
      this.isHasExistValue = true;
      return;
    }
    this.list = [
      new NormalPoint({
        value: null,
        startPoint: new Date().getFullYear(),
        startOperator: ">=",
        endPoint: null,
        endOperator: "<",
      }),
      new NormalPoint({
        value: null,
        startPoint: null,
        startOperator: ">=",
        endPoint: null,
        endOperator: "<",
      }),
    ];
  }
  emitAddForm() {
    this.eventManager.broadcast({
      name: "emit-data-form-row-start-end",
    });
    setTimeout(() => {
      this.addForm();
    }, 200);
  }
  addForm() {
    const point = this.list[this.list.length - 2];
    const addPoint = new NormalPoint();
    addPoint.startOperator = this.changeReverseOperator(point.endOperator);
    addPoint.startPoint = point.endPoint;

    const nextPoint = this.list[this.list.length - 1];
    nextPoint.startPoint = nextPoint.startPoint
      ? nextPoint.startPoint++
      : undefined;
    addPoint.endOperator = this.changeReverseOperator(nextPoint.startOperator);
    addPoint.endPoint = nextPoint.startPoint ? nextPoint.startPoint : undefined;

    this.list.splice(this.list.length - 1, 0, addPoint);
  }
  deleteForm(index) {
    const deleteItem = this.list[index];
    const beforePoint = new NormalPoint(this.list[index - 1]);
    const afterPoint = new NormalPoint(this.list[index + 1]);

    beforePoint.endOperator = this.changeReverseOperator(
      afterPoint.startOperator
    );
    beforePoint.endPoint = deleteItem.endPoint;
    this.list.splice(index, 1);
    this.list[index - 1] = beforePoint;
  }
  emitData() {
    this.eventManager.broadcast({
      name: "emit-data-form-row-start-end",
    });
    this.callApi$.next(true);
  }
  receiveDataForm(point, index) {
    ObjectUtil.parseFullProperty(this.list[index], point);
  }
  disabledButton(): boolean {
    this.errors.isError = this.list.some((item) => item.isError);
    this.errors.type = !this.typeId;
    return super.disabledButton();
  }
  save() {
    this.isSaved = true;
    const result = this.disabledButton();
    if (result) {
      return;
    }
    const body = {
      configs: (this.list || []).map((item: NormalPoint) => {
        return {
          beginYear: item.startPoint,
          endYear: item.endPoint,
          growthRate: item.value,
          beginRelationalOperatorId: item.startOperatorId,
          endRelationalOperatorId: item.endOperatorId,
        };
      }),
      assetId: this.assetId,
      typeId: this.typeId,
    };
    return this.comprehensivePlanService
      .updateConfigAnnualGrowth(body)
      .subscribe(
        (res: any) => {
          this.resetChange();
          this.eventManager.broadcast({
            name: "save-config-annual-growth",
          });
          this.eventManager.broadcast({
            name: "save-config-asset-annual-growth",
            content: {
              assetId: this.assetId,
            },
          });
          this.dialogService.showSuccess("Thiết lập đã được lưu!", "", {
            isNoDismissAll: true,
          });
          this.updateTime = Date.now();
          this.eventManager.broadcast({
            name: "show-backdrop-dialog",
          });
        },
        (err) => {
          // TODO
        }
      );
  }
  changeReverseOperator(operator) {
    let reverseOperator = "";
    switch (operator) {
      case ">":
        reverseOperator = "<=";
        break;
      case "<":
        reverseOperator = ">=";
        break;
      case ">=":
        reverseOperator = "<";
        break;
      case "<=":
        reverseOperator = ">";
        break;
    }
    return reverseOperator;
  }
  getDataTest() {
    this.list = [
      new NormalPoint({
        value: 5,
        startPoint: 2024,
        startOperator: ">=",
        endPoint: 2030,
        endOperator: "<",
      }),
      new NormalPoint({
        value: 10,
        startPoint: 2030,
        startOperator: ">=",
        endPoint: 2100,
        endOperator: "<",
      }),
    ];
  }
  setDefaultConfig() {
    if (this.config) {
      return;
    }
    this.config = new ConfigPoint({
      titleStart: "Từ năm",
      titleEnd: "Đến năm",
      titleValue: "% tăng trưởng bình quân",
      isSeparate: false,
    });
  }
  showWarning(message, title) {
    super.showWarning(message, title);
    this.eventManager.broadcast({
      name: "show-backdrop-dialog",
    });
  }
}
