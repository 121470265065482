import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { AppQueries } from "src/app/state";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { NumberUtil } from "../../../../common/utils/number.util";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: "pie-chart-comprehensive-plan",
  templateUrl: "./pie-chart-comprehensive-plan.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./pie-chart-comprehensive-plan.component.scss"],
})
export class PieChartComprehensivePlanComponent
  implements OnInit, OnDestroy, OnChanges
{
  subscriptions?: Subscription[] = [];
  @Input() dataChart: any;
  @Input() isLoading: boolean = false;
  @Input() isDrawChart: boolean = true;
  @Input() isVertical: boolean = true;
  @Input() isQuickView: boolean = false;
  @Input() isNoMessage: boolean = false;
  @Input() cutout: string;
  @Input() ratio: any;
  @Input() message: any = "Vui lòng nhập các thông tin tại bảng thu nhập.";
  @Input() id = "pie-chart-" + Math.floor(Math.random() * 1000);
  constructor(
    public appQueries: AppQueries,
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
    public router: Router,
    private modalService: NgbModal
  ) {}
  ngOnInit(): void {
    this.subscriptions = [];
  }
  ngOnDestroy(): void {
    this.eventManager.destroys(this.subscriptions);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataChart) {
      if (this.dataChart && this.dataChart.datasets) {
        this.dataChart.datasets.forEach((item) => {
          item.data = item.data.map((value) => NumberUtil.decimalAdjust(value));
        });
      }
    }
  }
}
